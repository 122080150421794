<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 11:12:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-09 11:29:47
-->
<template>
  <div>
    <el-card class="box-card" shadow="never" style="min-height:750px">
      <div slot="header" class="clearfix">
        <span>我的任务</span>
        <el-form ref="searchForm" :model="searchForm" size="small" class="searchForm">
          <el-form-item style="margin-bottom:0">
            <el-select v-model="searchForm.taskStatus" placeholder="任务状态" @change="handleChange()">
              <el-option
                v-for="(item,index) in status"
                :key="'status'+index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-if="list.length">
        <task-item v-for="(item,index) in list" :key="'taskItem'+index" :item="item" class="item"></task-item>
      </template>
      <template v-else>
        <empty text="暂无任务"></empty>
      </template>
      <pagination
        :listLoading="listLoading"
        @getList="getList"
        @setLimit="searchForm.size = $event"
        @setPage="searchForm.current = $event"
        :currentPage="searchForm.current"
        :pageSize="searchForm.size"
        :total="searchForm.total"
      ></pagination>
    </el-card>
  </div>
</template>

<script>
import empty from "@/components/empty/index.vue";
import taskItem from "./components/taskItem.vue";
import pagination from "@/components/pagination/index.vue";
import api from "@/api/list";
export default {
  name: "myTasks",
  components: {
    taskItem,
    pagination,
    empty,
  },
  data() {
    return {
      listLoading: false,
      searchForm: {
        taskStatus: "",
        current: 1,
        size: 10,
        total: 0,
        type:2
      },
      list: [],
      status: [
        {
          value: "",
          label: "请选择任务状态",
        },
        {
          value: "applying",
          label: "申请中",
        },
        {
          value: "doing",
          label: "进行中",
        },
        {
          value: "finish",
          label: "已完成",
        },
        {
          value: "nopass",
          label: "未通过",
        },
        {
          value: "cancelled",
          label: "已作废",
        },
      ],
    };
  },
  mounted() {
    //获取任务
    this.getList();
  },
  methods: {
    getList() {
      //获取我的任务列表
      this.listLoading = true;
      api.getMyTasks(this.searchForm).then((res) => {
        if (res.status == 200) {
          this.listLoading = false;
          this.list = res.data.records;
          this.searchForm.total = res.data.total;
        } else {
          console.error(res.message);
        }
      });
    },
    handleChange() {
      this.searchForm.current = 1;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.searchForm {
  float: right;
  margin: -9px 0 0 0;
  /deep/ .el-input__inner {
    border-color: #7385f4;
  }
}
</style>