<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 18:19:00
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 09:39:10
-->
<template>
  <div v-show="!listLoading&&total>pageSize" class="pagination-container">
    <el-pagination
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="dataCurrentPage"
      :page-sizes="pageSizes"
      :page-size="dataPageSize"
      :layout="layout"
      :pager-count="pagerCount"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "green-job-pagination",
  props: {
    listLoading: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default: function () {
        return [2, 5, 10, 15, 20];
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    pagerCount: {
      type: Number,
      default: 9,
    },
  },
  data() {
    return {
      dataCurrentPage: this.currentPage,
      dataPageSize: this.pageSize,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.dataPageSize = val;
      this.$emit("setLimit", val);
      this.$emit("getList");
    },
    handleCurrentChange(val) {
      this.dataCurrentPage = val;
      this.$emit("setPage", val);
      this.$emit("getList");
    },
  },
};
</script>

<style lang="less" scoped>
.pagination-container {
  padding: 24px 12px;
  text-align: center;
}
</style>
