<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-20 11:26:53
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-08-24 17:43:51
-->
<template>
  <div class="pub_empty_section">
    <img :src="img" />
    <span>{{text}}</span>
  </div>
</template>
<script>
export default {
  name: "emptyPage",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      img: require("./assets/empty_section.png"),
    };
  },
};
</script>
<style lang="less" scoped>
.pub_empty_section {
  display: block;
  text-align: center;
  padding: 50px;
  border-bottom: 1px dashed #ebeef5;
  background: #fff;
  img,
  span {
    display: inline-block;
    vertical-align: middle;
  }
  span {
    font-size: 14px;
    margin-left: 40px;
  }
}
</style>